import React, { useEffect } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const StyledTypography = styled(Typography)`
  font-size: 24vw;
  line-height: 0.8;

  ${breakpointsMedia({
    lg: css`
      font-size: 22vw;
    `,
  })}
`;

const StyledImage = styled(Image)`
  z-index: 1;
  position: relative;
`;

const StyledGrid = styled(Grid)`
  min-height: 100vh;

${breakpointsMedia({
    lg: css`
    min-height: 100%;
    `,
  })}
`;

function ErrorPage() {
  useEffect(() => {
  }, []);

  return (
    <Box
      tag="section"
      background="linear-gradient(180deg, #A318FF 0%, #7203BB 100%)"
    >
      <StyledGrid
        paddingRight={{ xs: '40px' }}
        paddingLeft={{ xs: '40px' }}
        gridTemplateRows={{ xs: '80px auto 1fr', lg: '80px auto 1fr' }}
      >
        {/* TITLE & BUTTON */}
        <Grid.Child
          gridRow="2"
          gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '6 / span 6' }}
        >
          <Typography
            variant="heading1"
            color="white.default"
            intlKey="404_ERROR_PAGE.TITLE"
            marginBottom="12x"
            textAlign="left"
          />
          <Typography
            variant="heading3"
            color="white.default"
            intlKey="404_ERROR_PAGE.CONTENT"
            marginBottom={{ xs: '5x', md: '7x', lg: '8x' }}
            textAlign="left"
            whiteSpace="pre-line"
          />

          <Button
            intlKey="404_ERROR_PAGE.BUTTON_LABEL"
            iconProps={{
              name: 'arrow-right',
              title: 'Conocer Nu',
            }}
            styleVariant="white"
            onClick={() => window.open('/', '_self')}
          />
        </Grid.Child>

        {/* ROBOT IMAGE */}

        <Grid.Child
          gridRow="3"
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 12' }}
          alignSelf="end"
          justifySelf="center"
        >
          <StyledImage
            srcSet={{
              xs: '404/robot-xs.png',
              lg: '404/robot-lg.png',
            }}
            alt="Ilustracion de un robot"
            maxWidth={{ xs: '100%', lg: '95%' }}
          />
        </Grid.Child>

        {/* BACK 404 TEXT */}

        <Grid.Child
          gridRow="3"
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 12' }}
          alignSelf="end"
          justifySelf="center"
        >
          <StyledTypography
            intlKey="404_ERROR_PAGE.BACK_TEXT"
            color="#9436E1"
            strong
          />
        </Grid.Child>
      </StyledGrid>
    </Box>
  );
}

export default ErrorPage;
